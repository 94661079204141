import { CanActivateFn } from '@angular/router';

export const LoadGuard: CanActivateFn = (route, state) => {
  // canLoad(){

  // }
  return true;

  // TODO: CanActivate function
  // constructor(
  //   private router: Router,
  //   private appService: AppService,
  //   private dataCheckService: DataCheckService
  // ) {}
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ):
  //   | Observable<boolean | UrlTree>
  //   | Promise<boolean | UrlTree>
  //   | boolean
  //   | UrlTree {
  //   var userLoggedIn = this.appService.isLoggedIn();
  //   let isSuperUser = this.dataCheckService.isSuperUser();
  //   if (userLoggedIn) {
  //     if (isSuperUser === true) {
  //       this.router.navigate(['gtadmin/dashboard']);
  //     } else {
  //       this.router.navigate(['/dashboard']);
  //     }
  //   }
  //   if (next.url[0].path == 'activate') {
  //     return true;
  //   }

  //   return true;
  // }
};
