<div class="row">
  <div class="col-xs-12 d-md-none d-sm-block">
    <div class="gt-logo-mobile">
      <img
        ngSrc="../../../assets/images/Guard_Tracker-Stacked-{{
          currentTheme
        }}.avif"
        alt="Guard Tracker"
        width="160"
        height="160"
        priority
        as="image"
      />
    </div>
  </div>
  <div class="col-xs-12 d-none d-md-block">
    <div class="gt-logo-desktop">
      <img
        ngSrc="../../../assets/images/Guard_Tracker-Lateral_Long_{{
          currentTheme
        }}.avif"
        alt="Guard Tracker"
        width="384"
        height="96"
        priority
        as="image"
      />
    </div>
  </div>
</div>

<div class="gt-p-1">
  <ng-container *ngIf="showErrorMsg">
    <div
      *ngIf="showErrorMsg !== undefined"
      class="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      <strong>{{ showErrorMsg }}</strong>
    </div>
    <div
      *ngIf="showSuccessMsg !== undefined"
      class="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      <strong>{{ showSuccessMsg }}</strong>
    </div>
    <form
      [formGroup]="userEmailForm"
      appFormEnterAction
      *ngIf="showExpiryEmail"
    >
      <div class="form-floating mb-3">
        <input
          class="form-control"
          type="text"
          id="email"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          placeholder="Email Address"
          autofocus
          formControlName="email"
        />
        <label for="email">Email Address</label>
      </div>
      <div class="text-end">
        <button
          class="btn btn-primary"
          type="button"
          [disabled]="!userEmailForm.valid"
          (click)="refreshExpiredLink()"
        >
          Submit
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="userLinkedSubscribers?.length">
    <div class="row row-cols-1 text-center gy-2">
      <em>Select instance</em>
      <div
        *ngFor="let subscriber of userLinkedSubscribers"
        (click)="selectSubscriber(subscriber)"
        class="col"
      >
        <div class="card contact-card">
          <div class="card-body pointer">
            <div class="fw-bold">{{ subscriber.name }}</div>
            <div class="small-font">
              {{ subscriber?.address?.full_address }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showDetail === 'change_password'">
    <form
      [formGroup]="passwordForm"
      (ngSubmit)="changePassword()"
      appFormEnterAction
    >
      <div class="form-floating mb-3">
        <input
          class="form-control"
          type="password"
          id="password"
          formControlName="password"
          placeholder="New Password"
        />
        <label for="password">New Password</label>
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="toggleShowPassword()"
        >
          <i
            class="bi"
            [ngClass]="showPassword ? 'bi-eye' : 'bi-eye-slash'"
          ></i>
        </button>
        <div
          *ngIf="passwordForm.controls['password'].hasError('minlength')"
          class="text-danger"
        >
          Must have at least 8 characters
        </div>
      </div>

      <div class="form-floating mb-3">
        <input
          class="form-control"
          type="password"
          id="confirm_password"
          formControlName="confirm_password"
          placeholder="Confirm Password"
        />
        <label for="confirm_password">Confirm Password</label>
        <div
          *ngIf="passwordForm.controls['confirm_password'].hasError('notMatch')"
          class="text-danger"
        >
          Passwords do not match
        </div>
      </div>

      <div class="row gy-2">
        <div class="col-6">
          <button
            type="button"
            class="btn btn-dark w-100"
            (click)="onCloseDialogue()"
          >
            Cancel
          </button>
        </div>
        <div class="col-6">
          <button
            type="submit"
            class="btn btn-primary w-100"
            [disabled]="!passwordForm.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showDetail === 'update_email'">
    <form [formGroup]="emailForm" (ngSubmit)="changeEmail()" appFormEnterAction>
      <div class="form-floating mb-3">
        <input
          class="form-control"
          type="text"
          id="old_email"
          formControlName="old_email"
          placeholder="Enter current email address"
          autocorrect="off"
          autocapitalize="off"
        />
        <label for="old_email">Enter current email address</label>
      </div>

      <div class="text-end">
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="!emailForm.valid || changeEmailPressed"
        >
          Submit
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="downloadFileUrlS3Dict?.file_url">
    <div class="text-center">
      <button class="btn btn-primary" (click)="downloadS3File()">
        Download Report
      </button>
    </div>
  </ng-container>
</div>
