import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { startAuthentication } from '@simplewebauthn/browser';

import { currentTheme } from '../../../global.variable';
import { AppService } from '../../app.service';
import { ProfileService } from '../../pages/profile/profile.service';
import { ModelDialogueService } from '../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../shared/services/data-check.service';
import { DeviceInfoService } from '../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { AuthService } from '../auth.service';
import { TooltipService } from '../../tooltip.service';

@Component({
  selector: 'gtapp-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  currentTheme: string = 'default';
  loginForm: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(null, Validators.required),
    auth_code: new UntypedFormControl(null),
    remember_login: new UntypedFormControl(true),
    password: new UntypedFormControl(null),
  });

  refreshToken: any;

  userEmailForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  message: any;

  showPassword: any = false;
  linkExpired: any;

  userLinkedSubscribers: any = [];
  selectSubscriberToken: any;

  fidoRegistered: any;
  fidoLogin: boolean = false;
  //the below value will change depending on the response of the 'verify_login' api.
  showForm = 'userNameForm';

  rememberLoginMultipleSubscribers: number = 0;
  isPwaApp: boolean = window.matchMedia('(display-mode: standalone)').matches;

  hideSignInLInkForgotPasswordBtn: boolean = false;
  isPasswordAlreadyset: boolean = false;

  inviteeEmail: string = '';
  presetEmail: string = '';
  currentAppVersion: string = '';
  previouslyEnteredEmail: string = '';
  isSuperUser: boolean = false;
  rememberLogin: number = 0;
  constructor(
    @Inject(DOCUMENT) private _document: any,
    public router: Router,
    private spinnerService: LoadingSpinnerService,
    private appService: AppService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private profileService: ProfileService,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService,
    private elRef: ElementRef,
    private deviceInfoService: DeviceInfoService,
    private tooltipService: TooltipService
  ) {
    // when user logins using fido; it opens a seperate window via the browser,
    //whcih we are not able to control as of yet.So if any errors occur on the FIDO;
    //the screen gets stuck on that screen and not our app. This is a cheeky way to bypass that issue.
    // we will store the response data or error message in sessionStorage
    //and just reloads the page forcefully taking the screen of the FIDO intermediate screen
    //and use the response stored and show it in our app page

    let subscribers = JSON.parse(sessionStorage.getItem('response') || '{}');

    if (subscribers?.data?.length) {
      this.getMultipleSubscriber(subscribers, true);
      setTimeout(() => {
        this.clearLocalStorage();
      }, 1000);
    } else {
      // check whether there is email present in the localstorage beacuse the user had clicked remember me
      let savedEmail = localStorage.getItem('email');
      if (savedEmail) {
        this.presetEmail = savedEmail;
        this.spinnerService.show();
        this.loginForm.controls['username'].setValue(savedEmail);
        this.loginForm.controls['remember_login'].setValue(true);
        this.checkUserName();
      }
    }
    let errorMessage = sessionStorage.getItem('errorMessage');
    if (errorMessage) {
      this.message = errorMessage;
      setTimeout(() => {
        this.clearLocalStorage();
      }, 1000);
    }

    const unblockMessage = localStorage.getItem('unblock_account');
    if (unblockMessage) {
      this.message = unblockMessage;
      setTimeout(() => {
        this.clearLocalStorage();
      }, 1000);
    }
  }
  clearLocalStorage() {
    sessionStorage.clear();
    localStorage.removeItem('unblock_account');
  }
  getMultipleSubscriber(response: any, fidoLogin = false) {
    this.message = null;
    this.userLinkedSubscribers = response['data'];
    this.selectSubscriberToken = response['token'];
    this.rememberLoginMultipleSubscribers =
      response['remember_login'] == true ? 1 : 0;
    this.fidoLogin = fidoLogin ? true : false;
  }
  checkAuthFailureInfo(info: any) {
    if (
      info?.auth_code_failure_count > 4 &&
      new Date().getTime() - new Date(info?.last_modified_at).getTime() <
        10 * 60 * 1000
    ) {
      this.hideSignInLInkForgotPasswordBtn = Boolean(
        info?.auth_code_failure_count > 4 &&
          new Date().getTime() - new Date(info?.last_modified_at).getTime() <
            10 * 60 * 1000
      );
    }
  }

  ngOnInit(): void {
    document.documentElement.setAttribute('data-bs-theme', 'light');
    this.isSuperUser = this.dataCheckService.isSuperUser();
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.isPwaApp = true;
      this.loginForm.controls['remember_login'].setValue(true);
      this.rememberLogin = 1;
    } else {
      this.isPwaApp = false;
    }
    this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
      if (
        'permissionStatus' in value &&
        value?.permissionStatus === 'locationDisabled'
      ) {
        this.message = 'Error: Location access not given';
      }
    });

    localStorage.removeItem('resetPasswordPWA');
    var element = document.querySelector('nb-card');
    element?.setAttribute('id', 'loginCard');
    element?.classList.add('display-login');

    this.spinnerService.show();

    var key = this.route.snapshot.paramMap.get('key');

    if (key) {
      if (this.router.url.includes('login')) {
        this.appService
          .gtExternalLink('register_login/check_gt_link', {}, key)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              if (response?.data) {
                this.getMultipleSubscriber(response);
              } else {
                this.appService.setUserData(response);
                this.loginSuccessAction(response);
              }
            } else {
              this.message = response['message'];
              this.spinnerService.hide();
            }
          });
      } else {
        this.appService
          .gtExternalLink('register_login/check_gt_link', {}, key)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.appService.setUserData(response);
            } else {
              this.message = response['message'];
              this.refreshToken = response['token'];
              this.linkExpired = true;
              this.spinnerService.hide();
            }
          });
      }
    } else {
      this.route.queryParams.subscribe((params: any) => {
        this.inviteeEmail = params?.invitee_email;
      });
      if (this.inviteeEmail) {
        this.spinnerService.show();
        this.presetEmail = this.inviteeEmail;
        this.loginForm.controls['username'].setValue(this.inviteeEmail);
        this.loginForm.controls['remember_login'].setValue(true);
        this.router.navigate(['/login']);
        this.checkUserName();
      }
      this.spinnerService.hide();
    }
  }

  ngAfterViewInit(): void {
    var element = document.querySelector('nb-card');
    element?.setAttribute('id', 'loginCard');
    element?.classList.add('display-login');
    setTimeout(() => {
      this.tooltipService.initTooltips(false, this.elRef.nativeElement);
    }, 100);
    this.appVersionCheck();
  }
  selectSubscriber(subscriber: any) {
    this.spinnerService.show();
    this.authService
      .selectSubscriber(
        this.selectSubscriberToken,
        subscriber.id,
        this.rememberLoginMultipleSubscribers
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          if (this.fidoLogin) response.checks.fido_login = true;
          this.appService.setUserData(response);
          this.loginSuccessAction(response);
        } else {
          this.message = response['message'];
          this.spinnerService.hide();
        }
      });
  }
  checkIfEmailChanged() {
    if (this.previouslyEnteredEmail !== this.loginForm.value?.username) {
      this.previouslyEnteredEmail = this.loginForm.value?.username;
      this.loginForm.controls['password'].clearValidators();
      this.loginForm.controls['password'].updateValueAndValidity();
      this.loginForm.controls['auth_code'].clearValidators();
      this.loginForm.controls['auth_code'].updateValueAndValidity();

      this.showForm = 'userNameForm';
    }
  }

  sendVCode(source: string = 'forgotPassword') {
    this.message = null;
    this.authService
      .sendVCode({ data: this.loginForm.value })
      .subscribe((response: any) => {
        this.message = response['message'];

        this.loginForm.controls['password'].clearValidators();
        this.loginForm.controls['password'].updateValueAndValidity();
        this.loginForm.controls['auth_code'].setValidators(Validators.required);
        this.loginForm.controls['remember_login'].setValue(true);

        this.showForm = 'vCodeForm';
        if (source === 'forgotPassword')
          window.localStorage.setItem('resetPasswordPWA', 'true');
      });
  }

  async loginSuccessAction(response: any) {
    sessionStorage.clear();
    localStorage.removeItem('unblock_account');
    delete response['message'];
    let userData = this.authService.getUserData();
    if (!userData) userData = response;
    userData['status'] = 'verified';
    this.authService.setUserData(userData);

    this.authService.checkDevice().then(() => {
      this.spinnerService.hide();
    });

    this.spinnerService.hide();

    if (this.dataCheckService.isSuperUser() === true) {
      this.router.navigate(['/gtadmin/dashboard']);
    } else {
      localStorage.setItem('loginSuccess', 'true');
      this.router.navigate(['./dashboard']);
    }
  }

  refreshExpiredLink() {
    this.profileService
      .refreshAndSendRegistrationLink(
        this.userEmailForm.value,
        this.refreshToken
      )
      .subscribe((response: any) => {
        this.message = response['message'];
        if (response['status'] == 'success') {
          this.linkExpired = false;
        }
      });
  }

  hideShowPassword() {
    this.showPassword = !this.showPassword;

    const verCodeInput: any = document.getElementById(
      'verCode'
    ) as HTMLInputElement;
    if (this.showPassword) {
      verCodeInput.style.webkitTextSecurity = 'none';
    } else {
      verCodeInput.style.webkitTextSecurity = 'disc';
    }
  }

  async fidoStartAuth(responseBody: any) {
    try {
      let authResp = await startAuthentication(JSON.parse(responseBody));

      if (authResp) {
        this.authService
          .fidoAuthenticate(
            {
              ...authResp,
              ...this.loginForm.value,
              isPwa: this.isPwaApp,
            },
            { verify: 1 }
          )
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              if (response?.data) {
                sessionStorage.setItem('response', JSON.stringify(response));
                window.location.reload();
              } else {
                response.checks.fido_login = true;
                this.appService.setUserData(response);
                this.loginSuccessAction(response);
              }
            } else {
              sessionStorage.setItem('errorMessage', response['message']);
              window.location.reload();
            }
          });
      }
    } catch (err: any) {
      sessionStorage.setItem('errorMessage', String(err));
      window.location.reload();
    }
  }

  authenticate() {
    this.spinnerService.show();
    var element = <HTMLInputElement>(
      document.getElementById('passwordInputField')
    );
    if (element) {
      element.hidden = true;
    }

    this.authService
      .fidoAuthenticate(this.loginForm.value)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.fidoStartAuth(response['data']);
        } else {
          this.message = response['message'];
        }
      });
  }
  checkUserName() {
    localStorage.removeItem('resetPasswordPWA');
    this.spinnerService.show();
    let requestData = {
      data: this.loginForm.value,
      url: 'register_login/verify_login/',
    };
    this.message = null;
    this.isPasswordAlreadyset = false;
    this.authService.verifyLogin(requestData).subscribe((response: any) => {
      if (response['status'] === 'success') {
        this.checkAuthFailureInfo(response?.data);
        this.fidoRegistered = response['data']?.fido_credentials;
        this.isPasswordAlreadyset = response?.data?.password_set;
        this.showForm = 'passwordForm';

        if (this.showForm === 'passwordForm') {
          var element = <HTMLInputElement>(
            document.getElementById('inputPassword')
          );
          element?.focus();
          // case where user/ email has logged into the app at-least once
          this.loginForm.controls['auth_code'].removeValidators(
            Validators.required
          );
          this.loginForm.controls['password'].setValidators(
            Validators.required
          );
        }
      } else {
        var element = <HTMLInputElement>(
          document.getElementById('inputPassword')
        );

        element?.focus();
        // even if the user entered email address is not in our db;
        // we will show the user password field and sign in button;
        //from there he can call with fake data or can modify the details and try loggin in
        this.showForm = 'dummyPasswordForm';
        this.loginForm.controls['auth_code'].removeValidators(
          Validators.required
        );
        this.loginForm.controls['password'].setValidators(Validators.required);
      }
      this.spinnerService.hide();
    });
  }

  signIn(url: String) {
    // handle captcha token
    this.spinnerService.show();
    let requestData = {
      data: { ...this.loginForm.value, isPwa: this.isPwaApp },
      url: url,
    };

    this.authService.verifyLogin(requestData).subscribe((response: any) => {
      if (response['status'] === 'success') {
        if (response?.data) {
          this.getMultipleSubscriber(response);
        } else {
          this.appService.setUserData(response);
          this.loginSuccessAction(response);
        }
      } else {
        this.message = response['message'];
      }
      this.checkAuthFailureInfo(response);
      this.spinnerService.hide();
    });
  }
  login() {
    if (this.showForm === 'userNameForm') {
      //step 1: check whether the entered email address is valid
      this.checkUserName();
    }
    if (this.showForm === 'vCodeForm') {
      //step 2:if user is first time user check v-code sent to their mail
      this.signIn('register_login/login_with_code/');
    }
    if (
      this.showForm === 'passwordForm' ||
      this.showForm === 'dummyPasswordForm'
    ) {
      //step 3:if user has logged in at-least once or entered email is not in our db.
      //In either case check again username + password is in the db
      this.signIn('register_login/login/');
    }
  }
  async appVersionCheck() {
    const versionData: any = await this.appService.appVersionCheck();
    const latestVersion = versionData?.latestVersion;
    const currentVersion = versionData?.currentVersion;
    this.currentAppVersion = currentVersion?.version;
    if (
      currentVersion?.version &&
      latestVersion?.last_item?.version !== currentVersion?.version
    ) {
      this.appService.updateAppVersionIndexedDb(
        latestVersion?.last_item?.version
      );
      this.appService.updateApp();
    }
    if (!currentVersion) {
      this.appService.updateAppVersionIndexedDb(
        latestVersion?.last_item?.version
      );
    }
  }
  ngOnDestroy(): void {
    this.tooltipService.disconnectObserver();
  }
}
