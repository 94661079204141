<div class="d-block d-md-none">
  <div class="gt-logo-mobile">
    <img
      ngSrc="../../../assets/images/Guard_Tracker-Stacked-{{
        currentTheme
      }}.avif"
      alt="Guard Tracker"
      width="160"
      height="160"
      priority
      as="image"
    />
  </div>
</div>
<div class="d-none d-md-block">
  <div class="gt-logo-desktop">
    <img
      ngSrc="../../../assets/images/Guard_Tracker-Lateral_Long_{{
        currentTheme
      }}.avif"
      alt="Guard Tracker"
      width="384"
      height="96"
      priority
      as="image"
    />
  </div>
</div>
<div class="text-center">
  <h2>Error 404 Page Not Found</h2>
  <small>This page does not exist</small>
</div>
<div class="text-center">
  <button class="btn btn-dark w-50 btn-lg mt-3" (click)="goToHome()">
    Back
  </button>
</div>
