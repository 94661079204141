import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ExternalAppComponent } from './external-app/external-app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ErrorPage503Component } from './error-page503/error-page503.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { LoadGuard } from '../shared/guards/load.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'gt/:key',
    component: ExternalAppComponent,
  },

  {
    path: 'delete-account',
    component: DeleteAccountComponent,
  },
  {
    path: 'activate/:key',
    component: LoginComponent,
    canActivate: [LoadGuard],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '503',
    component: ErrorPage503Component,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
