import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gtapp-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.scss',
})
export class DeleteAccountComponent {
  currentTheme: string = 'dark';
  isPwa = Boolean(window.matchMedia('(display-mode: standalone)').matches);
  constructor(private router: Router) {
    if (localStorage.getItem('from') !== 'deletion') {
      this.router.navigate(['/404']);
    }
    localStorage.clear();
  }

  closeWindow() {
    window.open('', '_parent', '');
    window.close();
  }
}
